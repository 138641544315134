import { Component } from '@angular/core';
import { FactorDetailBaseComponent } from '../factor-detail-base/factor-detail-base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';
import { CategoryData, ResearchSchemeOptionsFactorData } from 'src/app/models/kiwimonster-research/research-scheme-options.model';

@Component({
  selector: 'app-research-options',
  templateUrl: './research-options.component.html',
  styleUrls: ['./research-options.component.scss']
})
export class ResearchOptionsComponent extends FactorDetailBaseComponent {
  displayFirstHeaderColumns: string[] = [];
  displaySecondHeaderColumns: string[] = [];
  displayFirstFooterColumns: string[] = [];
  displaySecondFooterColumns: string[] = [];
  displayedColumns: string[] = [];
  allData: ResearchSchemeOptionsFactorData[] = [];
  categories: CategoryData[] = [];
  maxTotalScore: number = 0;
  constructor (
    public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public sysConfig: SysConfigService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super(kiwiMonsterResearchService, sharedFunction, loginService, sysConfig, router, route);
  }

  getData(openSnackBar: boolean = false): void {
    this.isLoadingData = true;

    this.kiwiMonsterResearchService.getSchemeOptionsFactorData((response) => {
      if (response && response.length > 0) {
        this.allData = response;

        this.sortByCategoryId(this.allData);
        this.getMaxTotalScore();

        this.kiwiMonsterResearchService.addRatingNameAndColor<ResearchSchemeOptionsFactorData>(this.allData, "QmRating", true, false);

        this.categories = this.getUniqueCategories(this.allData);
        this.generateColumns();

        // bind table data
        setTimeout(() => {
          this.bindTableData<ResearchSchemeOptionsFactorData>(this.allData, ['SchemeName'], 'Schemes Per Page', true, 25);
          if (openSnackBar) {
            this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
          }
        });
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.isLoadingData = false;
    });
  }

  getUniqueCategories(schemeOptionsFactorData: ResearchSchemeOptionsFactorData[]): CategoryData[] {
    let categoryMap = new Map<number, CategoryData>();

    // Collect unique categories
    schemeOptionsFactorData.forEach(scheme => {
      scheme.CategoryData.forEach(category => {
        if (!categoryMap.has(category.CategoryId)) {
          categoryMap.set(category.CategoryId, category);
        }
      });
    });

    // Convert map values to array and sort by CategoryId
    return Array.from(categoryMap.values()).sort((a, b) => a.CategoryId - b.CategoryId);
  }

  calculateValue(item: ResearchSchemeOptionsFactorData, index: number): void {
    // calculate CategoryData's CalculatedScore
    item.CategoryData[index].CalculatedScore = CategoryData.calculateScore(item.CategoryData[index]);
    // calculate ResearchSchemeOptionsFactorData's TotalScore
    item.TotalScore = item.CategoryData.reduce((sum, category) => sum + (category.CalculatedScore || 0), 0);
    // calculate List of ResearchSchemeOptionsFactorData's MaxTotalScore
    this.getMaxTotalScore();
    // calculate every ResearchSchemeOptionsFactorData's QmRating
    ResearchSchemeOptionsFactorData.calculateQmRatings(this.allData, this.maxTotalScore);
    // update every ResearchSchemeOptionsFactorData's RatingName and RatingNameBgColor
    this.kiwiMonsterResearchService.addRatingNameAndColor<ResearchSchemeOptionsFactorData>(this.allData, "QmRating", true, false);
  }

  getMaxTotalScore(): void {
    this.maxTotalScore = ResearchSchemeOptionsFactorData.getMaxTotalScore(this.allData);
  }

  resetEmptyValue(item: ResearchSchemeOptionsFactorData, property: string, i: number): void {
    if (!item.CategoryData[i][property]) {
      item.CategoryData[i][property] = 0;
    }
  }

  generateColumns(): void {
    this.displayFirstHeaderColumns = ['SchemeName']
      .concat(this.categories.map(category => category.CategoryName))
      .concat(['Scores', 'TotalScore', 'QmRating', 'RatingName']);

    this.displaySecondHeaderColumns = this.generateCategoryDataColumns(this.categories)
      .concat(this.categories.map(category => `CalculatedScore${ category.CategoryId }`));

    this.displayedColumns = ['SchemeName']
      .concat(this.displaySecondHeaderColumns)
      .concat(['TotalScore', 'QmRating', 'RatingName']);

    this.displayFirstFooterColumns = this.displayedColumns;
    this.displaySecondFooterColumns = this.categories.map(category => category.CategoryName).concat(['Scores']);
  }


  generateCategoryDataColumns(categories: CategoryData[]): string[] {
    let columns: string[] = [];
    categories.forEach((category) => {
      columns.push(`IsSelected${ category.CategoryId }`, `HowMany${ category.CategoryId }`, `Evidence${ category.CategoryId }`, `Pages${ category.CategoryId }`);
    });

    return columns;
  }

  isValidData(): boolean {
    return true;
  }

  update(): void {
    let updateData = this.allData.filter(item => item.HasChanged);
    if (updateData.length === 0) {
      return;
    }
    this.isLoadingData = true;
    updateData.forEach(item => {
      for (let key in item) {
        if (
          ![
            "QmOfferSchemeId",
            "CategoryData",
          ].includes(key)
        ) {
          delete item[key];
        }
      }
    });
    this.kiwiMonsterResearchService.updateSchemeOptionsFactorData(updateData, (response) => {
      this.getData(true);
    });
  }

  sortByCategoryId(schemeOptionsFactorData: ResearchSchemeOptionsFactorData[]): void {
    schemeOptionsFactorData.forEach(item => {
      if (item.CategoryData && item.CategoryData.length > 0) {
        item.CategoryData.sort((a, b) => a.CategoryId - b.CategoryId);
      }
    });
  }

  onValueChange(item: ResearchSchemeOptionsFactorData): void {
    item.HasChanged = true;
  }

  getCategoryShortName(categoryId: number): string {
    return CategoryData.getCategoryShortName(categoryId);
  }
  
  generateCSV(): void {
    let csvContent: string = '';

    // Header Row
    const headers: string[] = ['Scheme Name', 'Scheme Number'];
    for (let category of this.categories) {
      headers.push(`${ category.CategoryName } - Yes/No`, `${ category.CategoryName } - HowMany`, `${ category.CategoryName } - Evidence`, `${ category.CategoryName } - Pages`);
    }

    for (let category of this.categories) {
      headers.push(`${ category.CategoryName } -Scores`);
    }
    headers.push('Total', 'QM Calc Score', 'Rating Name');
    csvContent += headers.join(',') + '\n';

    // Data Rows
    for (let item of this.allData) {
      const row: string[] = [`${ item.SchemeName }`, `${ item.SchemeNumber }`];
      for (let i = 0; i < this.categories.length; i++) {
        row.push(
          item.CategoryData[i]?.IsSelected ? 'Yes' : 'No',
          item.CategoryData[i]?.HowMany?.toString() || '0',
          // Wrap URL in quotes to keep it as one cell
          `"${ item.CategoryData[i]?.Evidence || '' }"`, 
          `"${ item.CategoryData[i]?.Pages || '' }"`
        );
      }

      for (let i = 0; i < this.categories.length; i++) {
        row.push(
          item.CategoryData[i]?.CalculatedScore.toString() || '',
        );
      }
      row.push(
        item.TotalScore.toString()||'',
        item.QmRating.toString() || '',
        item.RatingName || ''
      );
      csvContent += row.join(',') + '\n';
    }

    // Trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'options-data.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-options", "generate csv");
  }

}
