
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule, MatStepperIntl } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { ObserversModule } from '@angular/cdk/observers';
import { PortalModule } from '@angular/cdk/portal';
import { DragDropModule } from '@angular/cdk/drag-drop';


// app start
import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/navmenu/navmenu.component';
import { HomeComponent } from './components/home/home.component';

// login register forgotten password
import { LoginComponent } from './components/login/login.component';
import { VerifyDeviceComponent } from './components/login/verify-device.component';

// quote component
import { QuoteClientComponent } from './components/bulk-quote/client-details/quote-client.component';

import { QuotePersonalBenefitComponent } from './components/bulk-quote/benefit-details/quote-p-benefit.component';
import { QuotePersonalBenefitSelectorComponent } from './components/bulk-quote/benefit-details/quote-p-benefit-selector.component';
import { QuotePersonalBenefitListComponent } from './components/bulk-quote/benefit-details/quote-p-benefit-list.component';
import { QuotePersonalBenefitSettingComponent } from './components/bulk-quote/benefit-details/quote-p-benefit-setting.component';
import { QuotePersonalBenefitResultComponent } from './components/bulk-quote/quote-results/quote-p-benefit-result.component';

import { QuoteReportDialogComponent } from './components/quote/quote-report-dialog.component';
import { QuoteStepCompare } from './service/quote/quote.step.compare';
import { QuoteStepCompareH2H } from './service/quote/quote.step.compare.h2h';
// setting component
import { QuoteSettingComponent } from './components/bulk-quote/provider-setting/quote-setting.component';
import { UserProviderSettingDialog } from './components/bulk-quote/provider-setting/quote-setting-dialog';

// research
import { ResearchPolicyDocumentComponent } from './components/legacy/policy/research-policy-document.component';

// qm data service
import { LoginService } from './service/login.service';
import { ApiAddressService } from './service/api.address.service';
import { DataLoaderService } from './service/data.loader.service';
import { DataLoader } from './service/data.loader';
import { QuoteService } from './service/quote.service';
import { QuoteStepClient } from './service/quote/quote.step.client';
import { QuoteStepBenefit } from './service/quote/quote.step.benefit';
import { UserService } from './service/user.service';
import { UserSecurityService } from './service/user.security.service';
import { SysConfigService } from './service/sys.config';
import { QprAdminService } from './service/qpr-admin.service';

// qm ads service
import { QmadsService } from './service/qmads.service';

// tools
import { SafeUrlPipe } from './service/safe.url.pipe';
import { SafeHtmlPipe } from './service/safe.html.pipe';
import { AutofocusDirective } from './tools/autofocus.directive';

import { CanDeactivateGuard } from './tools/can-deactivate.guard';
import { ConfirmMessageDialogService } from './components/shared/confirm-message-dialog/confirm-message-dialog.service';
import { ConfirmMessageDialogComponent } from './components/shared/confirm-message-dialog/confirm-message-dialog.component';
import { SharedFunctionService } from './service/shared.function.service';
import { ResizeService } from './service/screen.resize.service';

// routing
import { AppRoutingModule } from './app-routing.module';
import { Router, UrlSerializer } from '@angular/router';
import { ApiService } from './service/api.service';
import { LowerCaseUrlSerializer } from './tools/lower.case.url.serializer';

import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { UserMenuService } from './service/user.menu.service';
import { BulkQuoteComponent } from './components/bulk-quote/bulk-quote.component';
import { NativeElementInjectorDirective } from './tools/native-element-injector.directive';
import { AutoSelectDirective } from './tools/auto-select.directive';
import { LegacyComponent } from './components/legacy/legacy.component';
import { SelectSearchComponent } from './components/shared/select-search/select-search.component';
import { CompareVersionDialogComponent } from './components/legacy/compare-version-dialog/compare-version-dialog.component';
import { CreateVersionComponent } from './components/legacy/create-version/create-version.component';
import { LegacyLeavingGuard } from './tools/legacy-leaving-guard.guard';
import { VersionDetailComponent } from './components/legacy/version-detail/version-detail.component';
import { DisplayNumberFormatterDirective } from './tools/display-number-formatter.directive';
import { DefaultValueDirective } from './tools/default-value.directive';
import { ReplacementTargetsComponent } from './components/soa-ai-questions/replacement-targets/replacement-targets.component';
import { StandaloneHeadToHeadComponent } from './components/legacy/standalone-head-to-head/standalone-head-to-head.component';
import { AiQuestionsComponent } from './components/soa-ai-questions/ai-questions/ai-questions.component';
import { AiPromptComponent } from './components/soa-ai-questions/ai-prompt/ai-prompt.component';
import { AddProviderDialogComponent } from './components/legacy/add-provider-dialog/add-provider-dialog.component';
import { AddProductDialogComponent } from './components/legacy/add-product-dialog/add-product-dialog.component';
import { AddItemDialogComponent } from './components/legacy/add-item-dialog/add-item-dialog.component';

import {QmQprProvProdMappingComponent} from './components/qm-qpr-prov-prod-mapping/qm-qpr-prov-prod-mapping.component';
import {QmQprProvProdMappingBusComponent} from './components/qm-qpr-prov-prod-mapping-bus/qm-qpr-prov-prod-mapping-bus.component';

import {AdminNoticeComponent} from './components/admin-notice/admin-notices.component';
import {AdminNoticesService} from './service/notice/admin-notices.service';

import {QmadsAdminComponent} from './components/qmads/qmads-admin.component';
import { CheckMonsterComponent } from './components/checkmonster/checkmonster.component';
import { AddBannerDialogComponent } from './components/qmads/add-banner-dialog/add-banner-dialog.component';
import { ThirdPartyCompaniesComponent } from './components/third-party-companies/third-party-companies.component';
import { AddCompanyDialogComponent } from './components/third-party-companies/add-company-dialog/add-company-dialog.component';
import { KiwiMonsterFundsListComponent } from './components/kiwimonster/kiwimonster-funds-list.component';
import { KiwiMonsterFundDetailComponent } from './components/kiwimonster/kiwimonster-fund-detail/kiwimonster-fund-detail.component';
import { KiwiMonsterMorningStarDialogComponent } from './components/kiwimonster/kiwimonster-morningstar-dialog/kiwimonster-morningstar-dialog.component';
import { AdminPolicyDocComponent } from './components/admin-policy-doc/admin-policy-doc.component';
import { ManageDataComponent } from './components/admin-policy-doc/manage-data/manage-data.component';
import { ManageDataDialogComponent } from './components/admin-policy-doc/manage-data-dialog/manage-data-dialog.component';
import { AddPolicyDocComponent } from './components/admin-policy-doc/add-policy-doc/add-policy-doc.component';
import { UpdatePolicyDocDialogComponent } from './components/admin-policy-doc/update-policy-doc/update-policy-doc.component';
import { BulkQuoteDashboardComponent } from './components/bulk-quote/bulk-quote-dashboard/bulk-quote-dashboard.component';
import { ClientRiskComponent } from './components/client-risk/client-risk.component';
import { ResearchEvalCategoriesComponent } from './components/kiwimonster-research/research-eval-categories.component';
import { EvalCategoryDetailDialogComponent } from './components/kiwimonster-research/eval-category-detail-dialog/eval-category-detail-dialog.component';
import { ResearchValueComponent } from './components/kiwimonster-research/research-value/research-value.component';
import { ResearchSchemeSizeComponent } from './components/kiwimonster-research/research-scheme-size/scheme-size.component';
import { BackToCategoriesButtonComponent } from './components/kiwimonster-research/back-to-categories-button/back-to-categories-button.component';
import { EvalCategoryItemTableComponent } from './components/kiwimonster-research/eval-category-item-table/eval-category-item-table.component';
import { FactorDetailBaseComponent } from './components/kiwimonster-research/factor-detail-base/factor-detail-base.component';
import { ResearchAdviserSupportComponent } from './components/kiwimonster-research/research-adviser-support/research-adviser-support.component';
import { ResearchServiceComponent } from './components/kiwimonster-research/research-service/research-service.component';
import { ResearchToolsComponent } from './components/kiwimonster-research/research-tools/research-tools.component';
import { ManageToolsComponent } from './components/kiwimonster-research/research-tools/manage-tools/manage-tools.component';
import { CreateToolDialogComponent } from './components/kiwimonster-research/research-tools/manage-tools/create-tool-dialog.component';
import { ResearchManagementTenureComponent } from './components/kiwimonster-research/research-management-tenure/research-management-tenure.component';
import { ResearchPayoutsComponent } from './components/kiwimonster-research/research-payouts/research-payouts.component';
import {UserIGroupAccessControlComponent} from './components/user-igroup-access-control/user-igroup-access-control.component';
import {BlockUserOrIGroupDialogComponent} from './components/user-igroup-access-control/block-user-igroup-dialog/block-user-igroup-dialog.component';
import { ResearchOptionsComponent } from './components/kiwimonster-research/research-options/research-options.component';
import { ResearchGrowthComponent } from './components/kiwimonster-research/research-growth/research-growth.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    LoginComponent,
    VerifyDeviceComponent,
    HomeComponent,

    QuoteClientComponent,

    QuotePersonalBenefitComponent,
    QuotePersonalBenefitSelectorComponent,
    QuotePersonalBenefitListComponent,
    QuotePersonalBenefitSettingComponent,
    QuotePersonalBenefitResultComponent,

    QuoteReportDialogComponent,

    QuoteSettingComponent,
    UserProviderSettingDialog,

    ResearchPolicyDocumentComponent,

    ConfirmMessageDialogComponent,

    SafeUrlPipe,
    SafeHtmlPipe,
    AutofocusDirective,
    BulkQuoteComponent,
    NativeElementInjectorDirective,
    AutoSelectDirective,
    LegacyComponent,
    SelectSearchComponent,
    CompareVersionDialogComponent,
    CreateVersionComponent,
    VersionDetailComponent,
    DisplayNumberFormatterDirective,
    DefaultValueDirective,
    ReplacementTargetsComponent,
    StandaloneHeadToHeadComponent,
    AiQuestionsComponent,
    AiPromptComponent,
    AddProviderDialogComponent,
    AddProductDialogComponent,
    AddItemDialogComponent,

    // QPR admin part
    QmQprProvProdMappingComponent,
    QmQprProvProdMappingBusComponent,

    // notice admin part
    AdminNoticeComponent,
    QmadsAdminComponent,
    CheckMonsterComponent,
    AddBannerDialogComponent,
    ThirdPartyCompaniesComponent,
    AddCompanyDialogComponent,
    KiwiMonsterFundsListComponent,
    KiwiMonsterFundDetailComponent,
    KiwiMonsterMorningStarDialogComponent,
    AdminPolicyDocComponent,
    ManageDataComponent,
    ManageDataDialogComponent,
    AddPolicyDocComponent,
    UpdatePolicyDocDialogComponent,
    BulkQuoteDashboardComponent,
    ClientRiskComponent,
    ResearchEvalCategoriesComponent,
    EvalCategoryDetailDialogComponent,
    ResearchValueComponent,
    ResearchSchemeSizeComponent,
    BackToCategoriesButtonComponent,
    EvalCategoryItemTableComponent,
    FactorDetailBaseComponent,
    ResearchAdviserSupportComponent,
    ResearchServiceComponent,
    ResearchToolsComponent,
    ManageToolsComponent,
    CreateToolDialogComponent,
    ResearchManagementTenureComponent,
    ResearchPayoutsComponent,
    UserIGroupAccessControlComponent,
    BlockUserOrIGroupDialogComponent,
    ResearchOptionsComponent,
    ResearchGrowthComponent
  ],

  entryComponents: [
    UserProviderSettingDialog,
    QuoteReportDialogComponent,
    ConfirmMessageDialogComponent,
    CompareVersionDialogComponent,
    AddProviderDialogComponent,
    AddProductDialogComponent,
    AddItemDialogComponent,
    AddBannerDialogComponent,
    AddCompanyDialogComponent,
    KiwiMonsterMorningStarDialogComponent,
    ManageDataDialogComponent,
    UpdatePolicyDocDialogComponent,
    EvalCategoryDetailDialogComponent,
    CreateToolDialogComponent,
    BlockUserOrIGroupDialogComponent
  ],

  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CurrencyMaskModule,

    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatBadgeModule,
    MatStepperModule,
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    MatBottomSheetModule,
    DragDropModule,
    MatSidenavModule,

    AppRoutingModule,

    EditorModule
  ],
  providers: [
    QmadsService,
    ApiAddressService,
    SysConfigService,
    LoginService,
    DataLoaderService,
    DataLoader,
    QuoteService,
    QuoteStepClient,
    QuoteStepBenefit,
    QuoteStepCompare,
    QuoteStepCompareH2H,
    UserService,
    UserSecurityService,
    MatStepperIntl,
    SharedFunctionService,
    ResizeService,

    CanDeactivateGuard,
    ConfirmMessageDialogService,

    ApiService,
    UserMenuService,
    DatePipe,
    LegacyLeavingGuard,
    QprAdminService,
    AdminNoticesService,
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },

    {
      provide: MAT_DATE_LOCALE,
      useValue: "en-NZ",
    },

    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router) { }
}
