export class ResearchSchemeOptionsFactorData {
  QmOfferSchemeId: number;
  SchemeNumber: string;
  SchemeName: string;
  TotalScore: number;
  QmRating: number;
  CategoryData: CategoryData[];

  // UI only
  RatingName: string;
  RatingNameBgColor: string;
  HasChanged: boolean;

  static getMaxTotalScore(dataList: ResearchSchemeOptionsFactorData[]): number {
    return dataList.reduce(
      (max, item) => Math.max(max, item.TotalScore || 0),
      0
    );
  }

  static calculateQmRatings(dataList: ResearchSchemeOptionsFactorData[], maxTotalScore: number): void {
    if (!dataList || dataList.length === 0) return;

    // Calculate QmRating for each item
    dataList.forEach(item => {
      item.QmRating = maxTotalScore > 0 ? (item.TotalScore || 0) / maxTotalScore : 0;
    });
  }
}

export class CategoryData {
  Id: number;
  CategoryId: number;
  CategoryName: string;
  IsSelected: boolean;
  HowMany: number;
  Evidence: string;
  Pages: string;
  CalculatedScore: number;
  DateOfUpdateUTC: string;
  // UI Only
  DisplayEvidence: boolean;

  static calculateScore(item: CategoryData): number {
    let value = 0.0;

    switch (item.CategoryId) {
      case 1:
        if (item.HowMany > 5) {
          value = 9;
        }
        else if (item.HowMany > 4) {
          value = 8;
        }
        else if (item.HowMany > 3) {
          value = 7;
        }
        else if (item.HowMany > 2) {
          value = 6;
        }
        else if (item.HowMany > 1) {
          value = 5;
        }
        break;
      case 2:
        if (item.HowMany > 5) {
          value = 3;
        }
        else if (item.HowMany > 4) {
          value = 2;
        }
        else if (item.HowMany > 3) {
          value = 1.5;
        }
        else if (item.HowMany > 2) {
          value = 1;
        }
        else if (item.HowMany > 1) {
          value = 0.5;
        }
        break;
      case 3:
        value = item.HowMany > 0 ? 1 : 0;
        break;
      case 4:
        if (item.HowMany > 5) {
          value = 3;
        }
        else if (item.HowMany > 4) {
          value = 2;
        }
        else if (item.HowMany > 3) {
          value = 1.5;
        }
        else if (item.HowMany > 2) {
          value = 1;
        }
        else if (item.HowMany > 1) {
          value = 0.5;
        }
        break;
      case 5:
        value = item.HowMany > 0 ? item.HowMany : 0;
        break;
    }

    return value;

  }
  
  static getCategoryShortName(categoryId: number): string {
    let shortName = '';

    switch (categoryId) {
      case 1:
        shortName = 'Div';
        break;
      case 2:
        shortName = 'Single';
        break;
      case 3:
        shortName = 'Cycle';
        break;
      case 4:
        shortName = 'Non';
        break;
      case 5:
        shortName = 'Select Secur';
        break;
    }

    return shortName;
  }
}