import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { QuoteRequiredData } from '../models/quote.required.data';
import { ApiAddressService } from './api.address.service';
import { LoginService } from './login.service';
import { SharedFunctionService } from './shared.function.service';
import { DevTools } from './dev.tools';
import { ApiService } from './api.service';
import { GeneralResponseMessage } from '../models/messages/general.response.message';
import { HttpRequestUrl } from '../models/http.request.models/request.url';
import { DatePipe } from '@angular/common';



@Injectable({
    providedIn: 'root',
})
export class DataLoaderService {

    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(
        private http: HttpClient,
        private apiAddressService: ApiAddressService,
        private loginService: LoginService,
        private sharedFunction: SharedFunctionService,
        private apiService: ApiService,
        private datePipe: DatePipe
    ) {

    }


    // load json file
    loadRequiredJsonFile(): Observable<QuoteRequiredData> {

        let url: string = this.apiAddressService.getRequiredJsonUrl() + '?d=' + (new Date()).getTime();

        return this.http.get<QuoteRequiredData>(url, this.httpOptions)
            .pipe(
                tap(t => new DevTools().log(`loadRequiredJsonFile`, null)),
                catchError(this.sharedFunction.handleError('loadRequiredJsonFile', null))
            );

    }


    getRequiredDataFromServer(currentRequiredData: QuoteRequiredData, callback: (requiredData: QuoteRequiredData) => void) {
        // load qpr version.
        this.apiService.callApiWithoutAuth<QuoteRequiredData>(
            '', this.apiAddressService.getQprAndQeVersionUrl(), (response) => {
                if (response && response.QeVersion && response.QprVersion && response.AvailableProviders) {
                    // document.getElementById('qeversion').innerHTML = response.QeVersion;
                    // document.getElementById('qprversion').innerHTML = response.QprVersion;
                    currentRequiredData.AvailableProviders =
                        response.AvailableProviders;
                    currentRequiredData.QeVersion = response.QeVersion;
                    currentRequiredData.QprVersion = response.QprVersion;
                    currentRequiredData.UserAdviserTypeV2List =
                        response.UserAdviserTypeV2List;
                    currentRequiredData.DisputesResolutionServices =
                        response.DisputesResolutionServices;
                    currentRequiredData.SelectOptions = response.SelectOptions;
                    currentRequiredData.UserGroupList = response.UserGroupList;
                }
                callback(currentRequiredData);
            });
    }



    uploadFile(file: File, url: HttpRequestUrl, callback: (response: GeneralResponseMessage) => void) {

        // build form data with selected file.
        let formData = new FormData();
        formData.append('file', file);

        // call api.
        this.apiService.callApi<GeneralResponseMessage>(
            formData, url, (response) => {
                callback(response);
            });
    }

    // Call api to uplaod file with key values, keyValues format: var kvs = {"one": 1, "two": 2, "three": 3};
    uploadFileWithFormData(keyValues: {}, file: File, url: HttpRequestUrl, callback: (response: GeneralResponseMessage) => void){
        // build form data with selected file.
        let formData = new FormData();
        // add file into form
        formData.append('file', file);
        // add data into form
        if(keyValues != null){
            for(let key of Object.keys(keyValues)){
                formData.append(key, keyValues[key]);
            }
        }

        // call api.
        this.apiService.callApi<GeneralResponseMessage>(
            formData, url, (response) => {
                callback(response);
            });
    }
  
  uploadFileWithJsonFormData<T>(itemName: string, itemObj: T, files: File[], url: HttpRequestUrl, callback: (response: { Item: T, Message: GeneralResponseMessage; }) => void) {
    // Iterate over all properties of itemObj
    for (let key in itemObj) {
      if (itemObj.hasOwnProperty(key) && itemObj[key] instanceof Date) {
        // Convert all Date properties to 'yyyy-MM-dd'
        itemObj[key] = this.datePipe.transform(itemObj[key], 'yyyy-MM-dd') as any;
      }
    }

    // build form data with selected file.
    let formData = new FormData();

    // add object as a JSON string
    formData.append(itemName, JSON.stringify(itemObj));
    // add file into form
    if (files && files.length > 0) {
      files.forEach((file, index) => {
        formData.append('files', file);
      });
    }

    // call api.
    this.apiService.callApi<{ Item: T, Message: GeneralResponseMessage; }>(
      formData, url, (response) => {
        callback(response);
      });
  }

}
