import { Injectable } from '@angular/core';
import { HttpRequestUrl } from '../models/http.request.models/request.url';
import { SharedFunctionService } from './shared.function.service';



@Injectable({
  providedIn: 'root',
})
export class ApiAddressService {

  isDevMode:boolean = false;

  constructor(
    private sharedFunction: SharedFunctionService
  ) {
    this.isDevMode = this.sharedFunction.isQaSite();
  }

  private baseUrlTest: string = 'https://sandboxapiv4.quotemonster.co.nz/api/';
  private baseUrlLive: string = 'https://apiv3.quotemonster.co.nz/api/';
  private version: string = '';


  getBaseUrl(): string {
    if (this.isDevMode) {

      return this.baseUrlTest;
    } else {
      return this.baseUrlLive;
    }
  }

  getAccessTokenUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/GetAccessForAdmin', 'POST'
    );
  }

  getRefreshAccessTokenUrl() {
    return this.getBaseUrl() + this.version + 'User/Authorize/RefreshAccess';
  }

  getQprAndQeVersionUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData', 'GET'
    );
  }

  getQMTrackingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Tracking', 'PUT'
    );
  }

  getLicenseUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/License', 'GET'
    );
  }

  // pa function
  getPaAccessTokenUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/GetAccess', 'POST'
    );
  }

  getPaRefreshAccessTokenUrl() {
    return this.getBaseUrl() + this.version + 'PaUser/Authorize/RefreshAccess';
  }

  getUserProviderSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/ProviderSetting', 'GET'
    );
  }

  resetUserProviderSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/ProviderSetting', 'DELETE'
    );
  }

  updateUserProviderSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/ProviderSetting', 'PUT'
    );
  }

  removeUserCrossProviderUrl(providerId: number, benefitId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Setting/CrossProviderSetting?providerId=' + providerId + '&benefitId=' + benefitId, 'DELETE'
    );
  }

  getUserQmSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/QmSiteSetting', 'GET'
    );
  }

  updateUserQmSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/QmSiteSetting', 'PUT'
    );
  }

  updateUserProviderSpecialSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/SpecialProviderSetting', 'PUT'
    );
  }

  getUserDetailUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Detail', 'GET'
    );
  }

  getReSendUserDeviceVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/DeviceVerificationCode/ReSend', 'POST'
    );
  }

  checkUserDeviceVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/DeviceVerificationCode/Check', 'POST'
    );
  }

  getUserLoginDevicesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/MFA/LoginDevices', 'GET'
    );
  }

  getPolicyDocumentRequiredDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PolicyDoc/RequiredData', 'GET'
    );
  }

  getQprRequiredDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Research/RequiredData', 'GET'
    );
  }

  getPolicyDocumentListUrl(companyId, benefitId, productId, documentId, month, year): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version
      + 'PolicyDoc?companyId=' + companyId
      + '&benefitIdsString=' + benefitId
      + '&productId=' + productId
      + '&documentId=' + documentId
      + '&month=' + month
      + '&year=' + year, 'GET'
    );
  }

  // quote
  getDoCrunchUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote', 'POST'
    );
  }
  
  getDoBulkCrunchUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/BulkQuote', 'POST'
    );
  }
    
  sendBulkQuoteResultUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/BulkQuotes', 'POST'
    );
  }

  getSavedBulkQuotesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/SavedBulkQuoteRequests', 'GET'
    );
  }
  
  runSavedBulkQuotesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/RunSavedBulkQuotes', 'POST'
    );
  }
  
  getSavedBulkQuoteDetailUrl(requestId: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `Quote/SavedBulkQuoteRequest?requestId=${requestId}`, 'GET'
    );
  }
  
  deleteBulkQuotesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/SavedBulkQuoteRequests', 'DELETE'
    );
  }
  
  getQprPolicyWordingRequiredDataUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PolicyWording/RequiredData', 'GET'
    );
  }

  getQprItemDetailListUrl(productId: number, qprItemId: number, selectedProviderIds: string) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'PolicyWording?productId=' + productId
      + '&qprItemId=' + qprItemId
      + '&selectedProviderIds=' + selectedProviderIds, 'GET'
    );
  }

  getQuoteQprItemDetailListUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'PolicyWording/MixPolicyWording'
      , 'POST'
    );
  }

  getQmadsUrl(position: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/QmAds?position=' + position, 'GET'
    );
  }

  getCheckEmailUrl(email: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/CheckEmail?email=' + email, 'GET'
    );
  }

  getQprResultV3Url(quoteId: number, pCodes: string, cIndex: number, bIndex: number, orderBy: number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Research/QprRating?quoteId=' + quoteId
      + '&pCodes=' + pCodes
      + '&cIndex=' + cIndex
      + '&bIndex=' + bIndex
      + '&orderBy=' + orderBy, 'GET'
    );
  }


  getHeadToHeadResultUrl(quoteId: number, pCodes: string, cIndex: number, bIndex: number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Research/HeadToHead?quoteId=' + quoteId
      + '&pCodes=' + pCodes
      + '&cIndex=' + cIndex
      + '&bIndex=' + bIndex, 'GET'
    );
  }

  getQuoteResultReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/QuoteResult',
      'POST'
    );
  }

  getBankPremiumUrl(quoteId: number, selectedProviderQprCodes: string) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Quote/QuoteForAll?appId=' + quoteId
      + '&cqCodes=' + selectedProviderQprCodes,
      'GET'
    );
  }

  get10YearPremiumEstimateUrl(quoteId: number, selectedProviderQprCodes: string, clientIndex: number, benefitIndex: number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Quote/TenYearsPremium?appId=' + quoteId
      + '&cqCodes=' + selectedProviderQprCodes
      + '&cId=' + clientIndex
      + '&bId=' + benefitIndex,
      'GET'
    );

  }

  getHeadToHeadReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/HeadToHead',
      'POST'
    );

  }

  getReportV2Url() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/FullReport', 'POST'
    );
  }

  getPolicyWordingReportUrl(productId: number, qprItemId: number, selectedProviderIds: string) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/PolicyWording?productId=' + productId
      + '&qprItemId=' + qprItemId
      + '&selectedProviderIds=' + selectedProviderIds, 'GET'
    );
  }

  getMixProductPolicyWordingReport() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/MixPolicyWording', 'POST'
    );
  }

  getRequiredJsonUrl() {
    return '/assets/requiredData.json';
  }

  getQmQprMappingProvidersUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Research/QmQprMappingProviders', 'GET'
    );
  }

  getQmQprMappingItemsUrl(providerId : number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Research/QmQprMappingItems?providerId=' + providerId, 'GET'
    );
  }

  updateQmQprMappingItemsUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Research/QmQprMappingItems', 'POST'
    );
  }

  getBusQmQprMappingProvidersUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'BusinessResearch/QmQprMappingProviders', 'GET'
    );
  }

  getBusQmQprMappingItemsUrl(providerId : number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'BusinessResearch/QmQprMappingItems?providerId=' + providerId, 'GET'
    );
  }

  updateBusQmQprMappingItemsUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'BusinessResearch/QmQprMappingItems', 'POST'
    );
  }

  // system notice admin part
  getAdminSystemNoticesUrl(year:number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Notices/Admin/SystemNotices?year=' + year, 'GET'
    );
  }

  getAdminSystemNoticeUrl(noticeId:number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Notices/Admin/SystemNotice?id=' + noticeId, 'GET'
    );
  }

  saveAdminSystemNoticeUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Notices/Admin/SystemNotice', 'POST'
    );
  }

  getAdminQmAdsPositionsUrl(bannerType: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/QmAds/Positions/?bannerType=${bannerType}`, 'GET'
    );
  }
  
  getAdminQmAdsUrl(position: number, year: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/QmAds/?position=${position}&year=${year}`, 'GET'
    );
  }

  addOrUpdateAdminQmAdsUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'QmAdmin/QmAds', 'FILE_UPLOAD'
    );
  }

  // 2 factor login api
  getVerificationMethodsUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/GetVerificationMethods';
  }

  getCreateNewVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/CreateNewVerificationMethod';
  }

  getRemoveVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/RemoveVerificationMethod';
  }

  getCheckAndUpdateVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/CheckAndUpdateVerificationMethod';
  }

  getReSendVerificationMethodCodeUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/ReSendVerificationMethodCode';
  }

  getSetDefaultVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/SetDefaultVerificationMethod';
  }

  getUserDevicesUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/GetUserDevices';
  }

  getRemoveUserDeviceUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/RemoveUserDevice';
  }


  getUpdateUserDeviceNameUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/UpdateUserDeviceName';
  }

  // legacy QPR
  getLegacyQprCompaniesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/Companies', 'GET'
    );
  }
    
  createLegacyQprCompanyUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/Company', 'POST'
    );
  }
    
  getLegacyQprProductsUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/Products', 'GET'
    );
  }
    
  createLegacyQprProductUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/Product', 'POST'
    );
  }

  deleteLegacyQprProductUrl(legacyQprProvProdId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `LegacyQpr/Product?legacyProvProdId=${legacyQprProvProdId}`, 'DELETE'
    );
  }
    
  getLegacyQprSearchUrl(qprCompanyId: number | string, legacyCompanyId: number | string, productType: string, productLineCode: string, productCode: string, productCodeExt: string) {
    return new HttpRequestUrl(
        this.getBaseUrl() + this.version + `LegacyQpr/Search?qprCompanyId=${ qprCompanyId }&legacyCompanyId=${ legacyCompanyId }&productType=${ productType }&productLineCode=${ productLineCode }&productCode=${ productCode }&productCodeExt=${ productCodeExt }&publishStatus=3`, 'GET'
    );
  }

  deleteDocumentVersionUrl(versionId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `LegacyQpr/ProductVersion?provProdVersionId=${versionId}`, 'DELETE'
    );
  }
    
  createDocumentVersionUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/ProductVersion', 'POST'
    );
  }
    
  updateDocumentVersionUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/ProductVersion', 'PUT'
    );
  }
    
  getProductDocumentVersionDetailUrl(versionId: number, compareWithVersionId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `LegacyQpr/ProductVersion?provProdVersionId=${versionId}&compareWithProvProdVersionId=${compareWithVersionId}`, 'GET'
    );
  }
    
  getAvailableQprItemsUrl(provProdVersionId: number, compareWithProvProdVersionId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `LegacyQpr/QprItems?provProdVersionId=${provProdVersionId}&compareWithProvProdVersionId=${compareWithProvProdVersionId}`, 'GET'
    );
  }
    
  addAvailableQprItemsUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/QprItems', 'POST'
    );
  }
    
  deleteQprItemUrl(provProdVersionId: number, compareWithProvProdVersionId: number, qprItemId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `LegacyQpr/QprItem?provProdVersionId=${provProdVersionId}&compareWithProvProdVersionId=${compareWithProvProdVersionId}&qprItemId=${qprItemId}`, 'DELETE'
    );
  }
    
  getStandaloneH2HProductLinesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/ProductLines', 'GET'
    );
  }
    
  getLegacyH2HUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/Head2Head', 'POST'
    );    
  }
    
  getLegacyH2HReportUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/LegacyHeadToHead', 'POST'
    );    
  }
    
  getSoaAiReplacementTargetsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SoaAiReplacementTargets', 'GET'
    );
  }
    
  getSoaAiQuestionsUrl(qmDataMappingKey: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `NeedAnalysisService/SoaAiReplacementTargetQuestions?key=${qmDataMappingKey}`, 'GET'
    );
  }
    
  updateSoaAiQuestionsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SaveSoaAiReplacementTargetQuestions', 'POST'
    );
  }
    
  createSOAPreviewReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/PreviewSOA', 'POST'
    );
  }
  
  getCheckMonsterDataUrl(benefitId: number, typeOfData: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `CheckMonster/CheckMonsterDataTable?benefitId=${benefitId}&typeOfData=${typeOfData}`, 'GET'
    );
  }
  
  updateCheckMonsterDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'CheckMonster/CheckMonsterDataTable', 'POST'
    );
  }
  
  getThirdPartyCompaniesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'thirdCompany/Companies', 'GET'
    );
  }
  
  addThirdPartyCompanyUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'ThirdCompany/Company', 'POST'
    );
  }
  
  updateThirdPartyCompanyUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'ThirdCompany/Company', 'PUT'
    );
  }
  
  // admin kiwiMonster
  getAdminKiwiMonsterFundsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/Funds', 'GET'
    );
  }
  
  getAdminKiwiMonsterDetailUrl(linkId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `KiwiMonster/Admin/Fund?linkRecordId=${linkId}`, 'GET'
    );
  }
  
  getMorningStarFundsUrl(qMMBIEFundId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `KiwiMonster/Admin/SearchMorningStarFunds?qmMbieFundId=${qMMBIEFundId}`, 'GET'
    );
  }
  
  updateLinkMBIEAndMorningStarUrl(linkId: number, mBIEFundId: number, morningstarId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `KiwiMonster/Admin/LinkMBIEAndMorningStar?linkRecordId=${ linkId }&qmMbieFundId=${ mBIEFundId }&morningStarFundId=${ morningstarId }`, 'PUT'
    );
  }
  
  // admin Policy Documents
  getAdminPolicyDocumentsUrl(companyId: number, benefitId: number|string, productId: number|string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/PolicyDoc/Documents?companyId=${ companyId }&benefitId=${ benefitId }&productId=${ productId }`, 'GET'
    );
  }
  
  getAdminPolicyDocCompaniesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/PolicyDoc/Companies`, 'GET'
    );
  }

  getAdminPolicyDocBenefitsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/PolicyDoc/Benefits`, 'GET'
    );
  }

  getAdminPolicyDocProductsUrl(benefitId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/PolicyDoc/Products?benefitId=${ benefitId }`, 'GET'
    );
  }

  addUpdateDeleteCompanyUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/PolicyDoc/Company`, 'POST'
    );
  }

  addUpdateDeleteBenefitUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/PolicyDoc/Benefit`, 'POST'
    );
  }

  addUpdateDeleteProductUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/PolicyDoc/Product`, 'POST'
    );
  }
  
  addOrUpdateAdminPolicyDocUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'QmAdmin/PolicyDoc/Document', 'FILE_UPLOAD'
    );
  }

  getClientRisksUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'QmAdmin/QmClientRisks', 'GET'
    );
  }

  updateClientRisksUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'QmAdmin/QmClientRisks', 'POST'
    );
  }
  
  
  getResearchEvaluationCategoriesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/ResearchEvaluationCategories', 'GET'
    );
  }
  
  updateResearchEvaluationCategoryUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/ResearchEvaluationCategories', 'POST'
    );
  }
  
  getResearchValueFactorDataUrl(fundTypeCode: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `KiwiMonster/Admin/ResearchValueFactorData?fundTypeCode=${fundTypeCode}`, 'GET'
    );
  }

  getResearchSchemeSizeFactorDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/ResearchSchemeSizeFactorData', 'GET'
    );
  }

  getResearchAdviserSupportFactorDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/ResearchAdviserSupportFactorData', 'GET'
    );
  }

  createResearchAdviserSupportFactorDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/ResearchAdviserSupportFactorData', 'POST'
    );
  }

  updateResearchAdviserSupportFactorDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/ResearchAdviserSupportFactorData', 'PUT'
    );
  }
  
  getResearchSchemeRatingFactorDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/ResearchSchemeRatingFactorData', 'GET'
    );
  }
  
  updateResearchSchemeRatingFactorDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/ResearchSchemeRatingFactorData', 'PUT'
    );
  }

  getResearchServiceToolsFactorDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/ResearchSchemeServiceToolsFactorData', 'GET'
    );
  }
  
  updateResearchServiceToolsFactorDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/ResearchSchemeServiceToolsFactorData', 'PUT'
    );
  }
  
  createUpdateResearchServiceToolsDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/ResearchSchemeServiceToolsFactorData', 'PUT'
    );
  }
  
  getFundManagementTenureFactorDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/FundManagementTenureFactorData', 'GET'
    );
  }

  getFundManagementTenureFactorDataDetailUrl(qmSchemeFundId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `KiwiMonster/Admin/FundManagementTenureFactorDataDetail?qmSchemeFundId=${ qmSchemeFundId }`, 'GET'
    );
  }
  
  getSchemePayoutFactorDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/SchemePayoutFactorData', 'GET'
    );
  }
  
  updateSchemePayoutFactorDataUrl(years: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `KiwiMonster/Admin/SchemePayoutFactorData?howManyYears=${ years }`, 'POST'
    );
  }
  
  getSchemeOptionsFactorDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/SchemeOptionsFactorData', 'GET'
    );
  }

  updateSchemeOptionsFactorDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/SchemeOptionsFactorData', 'POST'
    );
  }

  getSchemeGrowthFactorDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/SchemeGrowthFactorData', 'GET'
    );
  }
  
  updateSchemeGrowthFactorDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/SchemeGrowthFactorData', 'POST'
    );
  }

  getAccessControlAvailableModulesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/UserOrIGAccessControl/AvailableModules`, 'GET'
    );
  }


  getAccessControlGetUsersOrInternalGroupsUrl(module: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/UserOrIGAccessControl/GetUsersOrInternalGroups?module=${module}`, 'GET'
    );
  }

  getAccessControlSearchUserUrl(keyword: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/UserOrIGAccessControl/SearchUser?keyword=${keyword}`, 'GET'
    );
  }

  getAccessControlSearchIGroupUrl(keyword: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/UserOrIGAccessControl/SearchIGroup?keyword=${keyword}`, 'GET'
    );
  }


  updateAccessControlBlockUserOrInternalGroupUrl(uId: number, gId: number, module: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/UserOrIGAccessControl/BlockUserOrInternalGroup?uId=${uId}&gId=${gId}&module=${module}`, 'POST'
    );
  }

  deleteAccessControlBlockUserOrInternalGroupUrl(uId: number, gId: number, module: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/UserOrIGAccessControl/UnblockUserOrInternalGroup?uId=${uId}&gId=${gId}&module=${module}`, 'DELETE'
    );
  }

}
