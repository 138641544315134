<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Research Options Factor</h5>
    </li>

    <!-- go back button -->
    <li class='list-group-item'>
      <back-to-categories-button [calculationCode]="currentEvalCategory.CalculationCode"></back-to-categories-button>
    </li>

    <!-- eval category table -->
    <li class="list-group-item border-top-dashed">
      <eval-category-item-table [currentEvalCategory]="currentEvalCategory"></eval-category-item-table>
    </li>

    <!-- search -->
    <li class="list-group-item border-top-dashed">
      <!-- name search -->
      <div class='input-group my-3'>
        <div class="input-group-prepend">
          <span class="input-group-text">
            <span class="material-icons" aria-hidden="false" aria-label="search icon">search</span>
          </span>
        </div>
        <input class="form-control" placeholder="Search Scheme Name" aria-label="Search"
               (keyup)="textFilter($event.target.value)" appAutoSelect>
      </div>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed" *ngIf='errorMessage'>
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- scheme table -->
    <li class="list-group-item border-top-dashed" *ngIf="allData.length > 0 && !errorMessage && !isLoadingData">
      <div class="text-right mb-3">
        <ng-template *ngTemplateOutlet="updateButton"></ng-template>
        <button mat-flat-button color="qm-blue" class='ml-4' (click)="generateCSV()">Export to
          CSV</button>
      </div>

      <div class='px-2'>
        <p class='text-right text-pumpkin'><strong>Max Total Score: {{maxTotalScore | number:'1.1-1'}}</strong></p>
      </div>
      <div class='table-responsive mt-3'>
        <table mat-table [dataSource]="dataSource" matSort class="table table-bordered table-info table-striped">
          <!-- Scheme Name -->
          <ng-container matColumnDef="SchemeName">
            <th rowspan='2' class='align-middle text-center' *matHeaderCellDef mat-sort-header>
              Scheme Name (Scheme Number)
            </th>
            <td class='align-middle w-min-240px' *matCellDef="let item">
              {{item.SchemeName}}<br>({{item.SchemeNumber}})
            </td>
            <th rowspan='2' class='align-middle text-center' *matFooterCellDef>Scheme Name (Scheme Number)</th>
          </ng-container>

          <!-- category header  -->
          <ng-container *ngFor="let category of categories, let i = index">
            <ng-container matColumnDef="{{category.CategoryName}}">
              <th class='text-center' *matHeaderCellDef colspan="4">{{category.CategoryName}}</th>
              <!-- This column doesn't generate <td> items, so no need to add a definition for them -->
              <th class='text-center' *matFooterCellDef colspan="4">{{category.CategoryName}}</th>
            </ng-container>
          </ng-container>

          <!-- Category Columns -->
          <ng-container *ngFor="let category of categories, let i = index">
            <ng-container matColumnDef="IsSelected{{category.CategoryId}}">
              <th class="align-middle" *matHeaderCellDef>Yes/No</th>
              <td class="align-middle w-110px w-min-110px w-max-110px" *matCellDef="let item">
                <select #isSelectedInput="ngModel" class="form-control"
                        [ngClass]="{ 'text-primary': isSelectedInput.touched && item.HasChanged }"
                        [(ngModel)]="item.CategoryData[i].IsSelected" (ngModelChange)='onValueChange(item)'>
                  <option [value]=true>Yes</option>
                  <option [value]=false>No</option>
                </select>
              </td>
              <th class='align-middle' *matFooterCellDef>YES/NO</th>
            </ng-container>

            <ng-container matColumnDef="HowMany{{category.CategoryId}}">
              <th class="align-middle" *matHeaderCellDef>How Many</th>
              <td class="align-middle  w-80px w-min-80px w-max-80px" *matCellDef="let item">
                <input class='form-control no-spin'
                       [ngClass]="{ 'text-primary': howManyInput.touched && item.HasChanged }"
                       #howManyInput="ngModel"
                       [(ngModel)]="item.CategoryData[i].HowMany"
                       currencyMask
                       [options]="{ allowNegative: false, prefix: '', precision : 0 , align : 'right'}"
                       (ngModelChange)="calculateValue(item,i); onValueChange(item)"
                       (blur)="resetEmptyValue(item,'HowMany',i)"
                       (wheel)="$event.target.blur()"
                       appAutoSelect>
              </td>
              <th class='align-middle' *matFooterCellDef>How Many</th>
            </ng-container>

            <ng-container matColumnDef="Evidence{{category.CategoryId}}">
              <th class="align-middle" *matHeaderCellDef>Evidence</th>
              <td class="align-middle w-160px w-min-160px" *matCellDef="let item">
                <textarea class="form-control" name="evidence" rows="1"
                          [ngClass]="{ 'text-primary': evidenceInput.touched && item.HasChanged }"
                          #evidenceInput="ngModel" [(ngModel)]='item.CategoryData[i].Evidence'
                          (ngModelChange)='onValueChange(item)'>
                </textarea>
                <!-- <ng-container *ngIf="!item.CategoryData[i].Evidence">
                  <button mat-icon-button disableRipple="true" class='qm-icon-btn-xsm ml-2 scale-80 align-text-bottom'
                          (click)='item.CategoryData[i].DisplayEvidence = !item.CategoryData[i].DisplayEvidence'
                          title='Edit Evidence'>
                    <span class="material-icons" *ngIf="item.CategoryData[i].DisplayEvidence !== true">add_circle</span>
                    <span class="material-icons" *ngIf="item.CategoryData[i].DisplayEvidence === true">cancel</span>
                  </button>
                </ng-container>
            
                <ng-container *ngIf="item.CategoryData[i].Evidence || item.CategoryData[i].DisplayEvidence">
                  <textarea class="form-control" name="evidence" rows="1" [ngClass]="{ 'text-primary': evidenceInput.touched }"
                            #evidenceInput="ngModel" [(ngModel)]='item.CategoryData[i].Evidence'></textarea>
                </ng-container> -->
              </td>
              <th class='align-middle' *matFooterCellDef>Evidence</th>
            </ng-container>

            <ng-container matColumnDef="Pages{{category.CategoryId}}">
              <th class="align-middle" *matHeaderCellDef>Pages</th>
              <td class="align-middle w-110px w-min-110px" *matCellDef="let item">
                <input type='text' class='form-control'
                       [ngClass]="{ 'text-primary': pagesInput.touched && item.HasChanged }"
                       #pagesInput="ngModel"
                       [(ngModel)]="item.CategoryData[i].Pages" (ngModelChange)='onValueChange(item)'
                       appAutoSelect>
              </td>
              <th class='align-middle' *matFooterCellDef>Pages</th>
            </ng-container>
          </ng-container>

          <!-- Scores header -->
          <ng-container matColumnDef="Scores">
            <th colspan='5' class='text-center' *matHeaderCellDef>Scores</th>
            <th colspan='5' class='text-center' *matFooterCellDef>Scores</th>
          </ng-container>

          <!-- Category Scores Columns -->
          <ng-container *ngFor="let category of categories, let i = index">
            <ng-container matColumnDef="CalculatedScore{{ category.CategoryId }}">
              <th class="align-middle" *matHeaderCellDef>{{ getCategoryShortName(category.CategoryId) }}</th>
              <td class="align-middle w-80px w-min-80px w-max-80px text-right" *matCellDef="let item">
                {{item.CategoryData[i].CalculatedScore | number:'1.1-1' }}
              </td>
              <th class='align-middle' *matFooterCellDef>{{ getCategoryShortName(category.CategoryId) }}</th>
            </ng-container>
          </ng-container>

          <!-- Total -->
          <ng-container matColumnDef="TotalScore">
            <th class='align-middle' rowspan='2' *matHeaderCellDef mat-sort-header>Total</th>
            <td class='align-middle text-right' *matCellDef="let item">
              {{item.TotalScore | number:'1.1-1' }}
            </td>
            <th class='align-middle' rowspan='2' *matFooterCellDef>Total</th>
          </ng-container>

          <!-- Rating -->
          <ng-container matColumnDef="QmRating">
            <th class='align-middle' rowspan='2' *matHeaderCellDef mat-sort-header>QM Calc Score</th>
            <td class='align-middle text-right' *matCellDef="let item">
              {{item.QmRating * 100 | number:'1.0-0' }}%
            </td>
            <th class='align-middle' rowspan='2' *matFooterCellDef>QM Calc Score</th>
          </ng-container>

          <!-- Rating Name-->
          <ng-container matColumnDef="RatingName">
            <th class='align-middle' rowspan='2' *matHeaderCellDef>Rating Name</th>
            <td class='position-rel w-min-120px' *matCellDef="let item">
              <p class='rounded mb-0 px-2 text-center font-weight-bolder qm-text-sm w-110px w-max-110px position-middle'
                 [ngClass]="item.RatingNameBgColor">
                {{item.RatingName}}</p>
            </td>
            <th class='align-middle' rowspan='2' *matFooterCellDef>Rating Name</th>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayFirstHeaderColumns" class='table-info'></tr>
          <tr mat-header-row *matHeaderRowDef="displaySecondHeaderColumns" class='table-info'></tr>
          <tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayFirstFooterColumns" class="table-info"></tr>
          <tr mat-footer-row *matFooterRowDef="displaySecondFooterColumns" class="table-info"></tr>
        </table>
      </div>
      <!-- paginator -->
      <mat-paginator class='qm-paginator'></mat-paginator>
    </li>

    <!-- action buttons -->
    <li class='list-group-item border-top-dashed' *ngIf='!isLoadingData'>
      <div class='d-flex justify-content-end'>
        <ng-template *ngTemplateOutlet="updateButton"></ng-template>
      </div>
    </li>

  </ul>
</div>

<ng-template #updateButton>
  <button mat-flat-button color="qm-pumpkin" [disabled]='!isValidData()'
          (click)='update();loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-options", "update scheme options data")'>
    Update
  </button>
</ng-template>